.App {
  text-align: center;
}

.cursor {
  cursor: url('./assets/mario_cursor.png'), auto;
}

body {
  margin: 0;
  background-color: #131626;
}

.container {
  height: 65vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

svg {
  height: 100%;
}

#CubeGroup1,
#CubeGroup2,
#CubeGroup3,
#CubeGroup4,
#CubeGroup5,
#CubeGroup6,
#CubeGroup7,
#CubeGroup8,
#CubeGroup9,
#CubeGroup10,
#CubeGroup11,
#CubeGroup12,
#CubeGroup13,
#CubeGroup14,
#CubeGroup15,
#CubeGroup16 {
  animation: waveAnimation 1.4s ease-in-out alternate infinite;
  animation-fill-mode: forwards;
}

#CubeGroup1 {
  fill: #ff0004;
}
#CubeGroup2 {
  fill: #ffa500;
}
#CubeGroup3 {
  fill: #FFFF00;
}
#CubeGroup4 {
  fill: #008000;
}
#CubeGroup5 {
  fill: #0000ff;
}
#CubeGroup6 {
  fill:	#4b0082;
}
#CubeGroup7 {
  fill: #EE82EE;
}
#CubeGroup8 {
  fill: #ff0004;
}
#CubeGroup9 {
  fill: #ffa500;
}
#CubeGroup10 {
  fill: #FFFF00;
}
#CubeGroup11 {
  fill: #008000;
}
#CubeGroup12 {
  fill: #0000ff;
}
#CubeGroup13 {
  fill: #4b0082;
}
#CubeGroup14 {
  fill: #EE82EE;
}
#CubeGroup15 {
  fill: #ff0004;
}
#CubeGroup16 {
  fill: #ffa500;
}


polygon {
  fill-opacity: 0.8;
}

@keyframes waveAnimation {
  to {
    transform: translateY(500px);
  }
}

#CubeGroup1 {
  animation-delay: 0.2s;
}

#CubeGroup2 {
  animation-delay: 0.4s;
}

#CubeGroup3 {
  animation-delay: 0.6s;
}

#CubeGroup4 {
  animation-delay: 0.8s;
}

#CubeGroup5 {
  animation-delay: 1s;
}

#CubeGroup6 {
  animation-delay: 1.2s;
}

#CubeGroup7 {
  animation-delay: 1.4s;
}

#CubeGroup8 {
  animation-delay: 1.6s;
}

#CubeGroup9 {
  animation-delay: 1.8s;
}

#CubeGroup10 {
  animation-delay: 2s;
}

#CubeGroup11 {
  animation-delay: 2.2s;
}

#CubeGroup12 {
  animation-delay: 2.4s;
}

#CubeGroup13 {
  animation-delay: 2.6s;
}

#CubeGroup14 {
  animation-delay: 2.8s;
}

#CubeGroup15 {
  animation-delay: 3s;
}

#CubeGroup16 {
  animation-delay: 3.2s;
}

.title-container {
  display: inline-block;
  justify-content: center;
  align-items: center;
  margin: 0px 0px 50px 0px; /* Increased margin for more spacing on the web version */
  border-radius: 7px;
  text-align: left;
  max-width: 100%; /* Ensures the image doesn't exceed its natural size */
  height: auto; /* Maintains the aspect ratio of the image */
}

.start-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.start-btn {
  text-align: center;
  display: inline-block;
  margin: 0px 30px; /* Increased margin for more spacing on the web version */
  font-weight: bold;
  width: auto;
  min-width: 300px; /* Increased minimum width for the buttons */
  padding: 15px 20px; /* Adjust padding for the web version */
  font-size: 30px; /* Adjust font size for the web version */
  background-color: lightgray;
  text-shadow: -1px -1px black, 1px 1px white;
  color: gray;
  border-radius: 7px;
  box-shadow: 0 .2em gray;
  font-family: 'Press Start 2p';
}


/* Media query for mobile responsiveness */
@media (max-width: 600px) {
  .start-btn-container {
      flex-direction: row; /* Keep buttons next to each other */
  }

  .start-btn {
      min-width: auto; /* Remove minimum width for mobile */
      margin: 0px 15px; /* Adjust margin for spacing on mobile */
      padding: 15px 10px; /* Adjust padding for mobile */
      font-size: 16px; /* Adjust font size for mobile */
      cursor: url('./assets/mario_cursor.png'), auto;
  }

  .title-container {
    display: flex;
    align-items: center; /* Vertically align items in the container */
    justify-content: center; /* Horizontally center items in the container */
    text-align: center; /* Center text within the container */
  }
  
  .title-container img{
      width: 90%; /* Adjust image width for mobile */
  }
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeOut {
  from { opacity: 1; }
  to { opacity: 0; }
}

.loading-screen {
  animation: fadeIn 1s ease-in-out;
}

/* LoadingScreen.css */
.loading-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: black;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.tv-noise {
  clip-path: inset(50% 0 50% 0);
  width: 100%;
  height: 100%;
  background-image: url('https://media.tenor.com/eIdDxPdvGqUAAAAC/old-tv.gif');
  background-size: cover;
  position: absolute;
  animation: revealNoise 1s ease-in-out forwards;
}

@keyframes revealNoise {
  0% {
    clip-path: inset(50% 0 50% 0); /* Thin line */
  }
  100% {
    clip-path: inset(0 0 0 0); /* Full rectangle */
  }
}


.startup_message {
  animation: fadeIn 1s ease 5s forwards;
  color: white;
  font-size: 40px;
  z-index: 1;
  position: fixed;
  top: 10px;
  right: 10px;
  font-family:'VT323';
}

.screen-on {
  width: 1px;
  height: 1px;
  background-color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation-timing-function: ease-in-out; /* Apply easing */
  animation: turnOn 1s 1s forwards; /* Adjust total duration as needed */
}

@keyframes turnOn {
  20% {
    width: 1px;
    height: 1px;
  }
  100% { /* Dot transforms into a perfect horizontal line */
    width: 100%;
    height: 1px;
    transform: translate(-50%, -50%);
  }
}